.cc_banner-wrapper {
    z-index: 9001; // It's over 9000!
    position:relative;
    width: 100%; 
}

.cc_container {
	padding: 40px 0;
	position: fixed;
    left:0;
    right:0;
    bottom:0;
    overflow:hidden;
    color: #fff;
    font-size:17px;
    font-family: "Roboto", "Helvetica Neue", "Helvetica", "Arial";
    letter-spacing: 1px;
    box-sizing:border-box;
    border-top:0px solid #fff;

    .cc_btn {
        cursor: pointer;
        text-align: center;
        font-size: .9em;
		margin: 20px 0 0 0;
        display: inline-block;
		padding: 9px 20px;
		font-weight: 500;
		line-height: 1.5em;
        background-color: #fff;
		border-color: #fff!important;
		color: #676363;
        border: 1px solid;
        border-radius: 4px;
        -webkit-border-radius: 4px;
     }   
        
    @media (min-width: 992px) {
      .cc_btn {
        display: inline-block;
        margin: 0;
        font-size: .9em; } }
    
    .cc_btn:hover,
    .cc_btn:focus,
    .cc_btn:active {
        background-color: #676363;
		border-color: #676363!important;
		color: #fff;
    }

    .cc_message {
        font-size:1em;
        margin: 0;
        padding: 11px 0 0 0;
        line-height:1.5em;
        display: block;
    }

    .cc_more_info {

    }

    a,
    a:visited {
        text-decoration: none;
        color: #f78736;
    }

    a:hover,
    a:focus,
    a:active {
        color: #e9e9e9;
    }
}


@media print {
    .cc_banner-wrapper,
    .cc_container {
        display:none;
    }
}



$plugin-height: 66px;

/* Fade in up
------------------------------------ */
@-webkit-keyframes slideUp {
    0% {

        -webkit-transform: translateY($plugin-height);
        transform: translateY($plugin-height);
    }

    100% {

        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@keyframes slideUp {
    0% {

        -webkit-transform: translateY($plugin-height);
        -ms-transform: translateY($plugin-height);
        transform: translateY($plugin-height);
    }

    100% {

        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
    }
}



.cc_container,
.cc_message,
.cc_btn {

    animation-duration: 0.8s;
    -webkit-animation-duration: 0.8s;
    -moz-animation-duration: 0.8s;
    -o-animation-duration: 0.8s;

    -webkit-animation-name: slideUp;
    animation-name: slideUp;
}


.cookieconsent {
  padding: 0 15px;  
  margin: 0 2%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  color: #fff; }
  @media (min-width: 768px) {
    .cookieconsent {
      padding: 0 15px; } }
  .cookieconsent p {
  	color: #fff;
    font-size: 1.3em;
    line-height: 1.8em;
    margin: 0;
    padding: 0; }
    @media (min-width: 992px) {
     .cookieconsent p {
        display: inline;
        float: left;
        } }
                
    @media (min-width: 600px) {
	.cookieconsent {
		margin: 0 3.1% } }
		
	@media (min-width: 768px) {
	.cookieconsent {
		margin: 0 4.2% } }
		
	@media (min-width: 992px) {
	.cookieconsent {
		margin: 0 5.5% } }
		
	@media (min-width: 1200px) {
	.cookieconsent {
		margin: 0 6.5% } }
		
	@media (min-width: 1300px) {
	.cookieconsent {
		margin: 0 auto;
		max-width: 1150px } }
        
.cookieconsent .left {
	text-align: center;
	width: 100%; }
	 @media (min-width: 992px) {
	 .cookieconsent .left {
		float:	left;
		text-align: left;
		width: 66%; } }
	
.cookieconsent .right {
	text-align: center;
	width: 100%; }
	 @media (min-width: 992px) {
	 .cookieconsent .right {
		float:	right;
		text-align: right;
		width: 33%; } }
	
.cookieconsent .btn {
    display: inline-block;
    text-align: center; }
    @media (min-width: 992px) {
      .cookieconsent .btn {
        display: inline-block;
        margin: 0;
        font-size: .9em; } }
